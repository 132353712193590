import { createRouter, createWebHistory } from '@ionic/vue-router';
import Mainlayout from '../views/mainlayout.vue'
import Pagelayout from '../views/pagetemplate.vue'

const routes = [
  {
    path: '/login',
    component: () => import ('../views/login.vue')
  },
  {
    path: '/newregist',
    component: () => import ('../views/newregist.vue')
  },
  {
    path: '/passwordForget',
    component: () => import ('../views/forgetpassword.vue')
  },
  {
    path: '/pwresetRequested',
    component: () => import ('../views/resetpasswordRequested.vue')
  },
  {
    path: '/preregisted',
    component: () => import ('../views/preregisted.vue')
  },
  {
    path: '/userRegist',
    component: () => import ('../views/userregist.vue')
  },
  {
    path: '/studentRegist',
    component: () => import ('../views/studentregist.vue')
  },
  {
    path: '/main/',
    component: Mainlayout,
    meta: {pageTitle: 'トップページ'},
    children: [
      {
        path: 'pages/',
        component: Pagelayout,
        children: [
          {
            path: 'top',
            component: () => import ('../views/pages/topmenu.vue'),
            meta: {pageTitle: 'トップページ'}
          },
          {
            path: 'information',
            component: () => import ('../views/pages/information.vue'),
            meta: {pageTitle: '園からのお知らせ'}
          },
          {
            path: 'healthcheck',
            component: () => import ('../views/pages/healthcheck.vue'),
            meta: {pageTitle: '体調管理'}
          },
          {
            path: 'iccard',
            component: () => import ('../views/pages/iccard.vue'),
            meta: {pageTitle: '入構証発行管理'}
          },
          {
            path: 'iccard/applicate',
            component: () => import ('../views/pages/iccard-applicate.vue'),
            meta: {pageTitle: '入構証発行管理 - 申請'}
          },
          {
            path: 'iccard/regist',
            component: () => import ('../views/pages/iccard-regist.vue'),
            meta: {pageTitle: '入構証発行管理 - 紐付け登録'}
          },
          {
            path: 'surveys',
            component: () => import ('../views/pages/surveys.vue'),
            meta: {pageTitle: '回答受付中の調査'}
          },
          {
            path: 'survey/seikatsu/:stnumber/:branchnumber',
            component: () => import ('../views/pages/survey/survey-seikatsu.vue'),
            meta: {pageTitle: '幼児生活調査票'}
          },
          {
            path: 'survey/seikatsu-view/:stnumber/:branchnumber',
            component: () => import ('../views/pages/survey/survey-seikatsu-view.vue'),
            meta: {pageTitle: '幼児生活調査票 参照'}
          },
          {
            path: 'students',
            component: () => import ('../views/pages/students.vue'),
            meta: {pageTitle: '登録園児情報'}
          },
          {
            path: 'studentAdd',
            component: () => import ('../views/pages/studentadd.vue'),
            meta: {pageTitle: '園児追加登録'}
          },
          {
            path: 'foodservices',
            component: () => import ('../views/pages/foodservices.vue'),
            meta: {pageTitle: '給食利用'}
          },
          {
            path: 'foodservices/setting/:stnumber',
            component: () => import ('../views/pages/foodservices-setting.vue'),
            meta: {pageTitle: '給食利用 - 発注設定'}
          },
          {
            path: 'extusages',
            component: () => import ('../views/pages/extusages.vue'),
            meta: {pageTitle: '預かり保育利用状況'}
          },
          {
            path: 'invoices',
            component: () => import ('../views/pages/invoices.vue'),
            meta: {pageTitle: '保育料等情報'}
          },
          {
            path: 'extreserve/list',
            component: () => import ('../views/pages/extreserve.vue'),
            meta: {pageTitle: '一日預かり保育予約'}
          },
          {
            path: 'extreserve/recept/:reserveid',
            component: () => import ('../views/pages/extreserve-recept.vue'),
            meta: {pageTitle: '一日預かり保育予約'}
          },
          {
            path: 'account',
            component: () => import ('../views/pages/account.vue'),
            meta: {pageTitle: '設定'}
          },
          {
            path: 'account/changeName',
            component: () => import ('../views/pages/account-changename.vue'),
            meta: {pageTitle: '設定 - 登録名義変更'}
          },
          {
            path: 'account/changeEmail',
            component: () => import ('../views/pages/account-changeemail.vue'),
            meta: {pageTitle: '設定 - Email変更'}
          },
          {
            path: 'account/addContact',
            component: () => import ('../views/pages/account-addcontact.vue'),
            meta: {pageTitle: '設定 - 連絡先追加'}
          },
          {
            path: 'account/deleteContact/:id',
            component: () => import ('../views/pages/account-deletecontact.vue'),
            meta: {pageTitle: '設定 - 連絡先削除'}
          },
          {
            path: 'account/secureGate',
            component: () => import ('../views/pages/account-securegate.vue'),
            meta: {pageTitle: 'セキュリティ確認'}
          }
        ]
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
